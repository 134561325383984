import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import { Link } from "gatsby";

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Page introuvable" />
        <h1>404: Not Found</h1>
        <p>Cette page est introuvable.</p>
        <Link
            to="/"
        >
            Revenir à l'accueil.
        </Link>
    </Layout>
)

export default NotFoundPage
